/* Styling for the footer container */
.footer-container {
    height: 8vh; /* Allow the container to expand based on content */
    width: 100%;
    background-color: #2c3c35;
    font-family: 'Nunito Sans', sans-serif;
    font-size: smaller;
    font-weight: 600;
    display: flex;
    flex-direction: column; /* Set column layout to stack elements vertically */
    justify-content: center; /* Vertically center-align elements */
    align-items: center; /* Horizontally center-align elements */
}

/* Styling for the right column (optional) */
.footer-right-column {
    align-items: flex-end; /* Align elements to the right within the right column */
}

.footer-terms,
.footer-privacy,
.footer-dslabs-copyright {
    display: block; /* Display elements as block elements (stack vertically) */
    text-align: center; /* Center text within each element */
    color: #fff;
}
