.widget-configurator-container {
    padding: 1rem;
}
.settings-container {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}
.color-picker {
    width: 3rem;
    height: 3rem;
    border: none;
    -webkit-appearance: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

.slider {
    max-width: 250px;
    min-width: 200px;
}

.button-preview-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 200px;
}

.preview-button {
    max-width: 300px;
}

.generated-code {
    background-color: transparent;
    padding: 1rem 2rem;
    max-width: 80ch;
    white-space: pre-wrap; /* Maintains spaces and line breaks but wraps text */
    word-wrap: break-word; /* Ensures that words do not overflow their container */
    overflow-wrap: break-word;
}
