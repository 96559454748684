.setup-container {
    padding: 0;
    margin: 0;
}

.setup-platform {
    padding: 2rem;
}

.setup-magic {
    padding: 2rem;
}

.setup-magic-desc {
    padding-bottom: 1rem;
}

.setup-step-number {
    text-align: left;
    font-size: 3rem;
    margin: 0;
}

.setup-step-title {
    text-align: left;
}

.setup-platform-heading {
    font-size: clamp(1.5rem, 1.5vw, 2rem);
}

.setup-img {
    width: auto;
    max-width: 100%;
    max-height: 60vh;
    margin: 0 auto;
    border-radius: 1rem;
}

.setup-shopify-button {
    max-width: 160px;
    margin: 0;
    padding: 0;
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
}

.setup-shopify-button:hover {
    transform: translateY(-2px);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.sitebuilder-button {
    padding: 1rem;
    border-radius: 1rem;
    border: none;
    background-color: #eee;
    cursor: pointer;
    width: 100%;
    height: 6rem;
    color: black;
}

.sitebuilder-button:hover {
    background-color: #f6f6f6;
}

.sitebuilder-button-image {
    height: 3rem;
}

.back-button {
    background-color: transparent;
    border: none;
    font-size: 3rem;
    cursor: pointer;
    color: #444;
}

.back-button:hover {
    color: black;
}

@media (min-width: 900px) {
    .setup-platform {
        padding: 3rem;
    }
    .setup-magic {
        padding: 3rem;
    }
    .setup-step-number {
        text-align: center;
    }
}
