.hero-title {
    font-family: "Poppins", sans-serif;
    font-size: clamp(2.2rem, 3.4vw, 3.8rem);
    font-weight: 700;
    line-height: 1.2;
    padding-left: 1rem;
}

.hero-subtitle {
    font-family: "Nunito sans", sans-serif;
    font-size: clamp(1.2rem, 2vw, 1.4rem);
    font-weight: 400;
    opacity: 0.62;
    padding-left: 1rem;
    margin: 0.8rem 0rem !important;
}

.hero-button-container {
    display: flex;
    justify-content: left;
}

.hero-media-container {
    position: relative;
    width: 100%;
    padding-bottom: 80%; /* Adjust for 0 computed height */
    height: auto;
    overflow: hidden;
}

.hero-media-container img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    object-fit: cover;
}
.fade-up {
    opacity: 0; /* Initially hidden */
    animation-name: fadeIn; /* Reference the animation */
    animation-duration: 1s; /* Adjust animation duration */
    animation-iteration-count: 1; /* Animate only once */
    animation-fill-mode: forwards; /* Keep final opacity after animation */
    animation-delay: 0.6s; /* Delay for the second image */
    aspect-ratio: 5/4;
}
.fade-up2 {
    opacity: 0; /* Initially hidden */
    animation:
        fadeIn 1s forwards 1.5s,
        moveAround 8s infinite 2.5s;
    aspect-ratio: 5/4;
}
.fade-up3 {
    opacity: 0; /* Initially hidden */
    animation: moveAroundCursor 8s infinite 2.5s;
    aspect-ratio: 1440/1152;
}
.hero-bg-img {
    aspect-ratio: 5/4;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(80px) scale(1.3, 1.3);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1, 1);
    }
}

@keyframes moveAround {
    0% {
        transform: translateY(0) translateX(0) scale(1, 1);
    }
    24% {
        transform: translateY(0) translateX(0) scale(1, 1);
    }
    32% {
        transform: translateY(0) translateX(0) scale(1.1, 1.1);
    }
    40% {
        transform: translateY(40px) translateX(-40px) scale(1.1, 1.1);
    }
    65% {
        transform: translateY(40px) translateX(-40px) scale(1.1, 1.1);
    }
    75% {
        transform: translateY(20px) translateX(40px) scale(1.1, 1.1);
    }
    85% {
        transform: translateY(20px) translateX(40px) scale(1.1, 1.1);
    }
    95% {
        transform: translateY(0) translateX(0) scale(1.1, 1.1);
    }
    100% {
        transform: translateY(0) translateX(0) scale(1, 1);
    }
}

@keyframes moveAroundCursor {
    0% {
        opacity: 0;
        transform: translateY(0) translateX(0) scale(1, 1);
    }
    24% {
        opacity: 0;
        transform: translateY(0) translateX(0) scale(1, 1);
    }
    32% {
        opacity: 0.9;
        transform: translateY(0) translateX(0) scale(1.1, 1.1);
    }
    40% {
        transform: translateY(40px) translateX(-40px) scale(1.1, 1.1);
    }
    65% {
        transform: translateY(40px) translateX(-40px) scale(1.1, 1.1);
    }
    75% {
        transform: translateY(20px) translateX(40px) scale(1.1, 1.1);
    }
    85% {
        transform: translateY(20px) translateX(40px) scale(1.1, 1.1);
    }
    95% {
        opacity: 0.9;
        transform: translateY(0) translateX(0) scale(1.1, 1.1);
    }
    100% {
        opacity: 0;
        transform: translateY(0) translateX(0) scale(1, 1);
    }
}

.hero-gradient-text {
    /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: linear-gradient(45deg, #3fa9ff, #3a0069);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.hero-media {
    width: 100%;
    height: auto;
    max-height: 600px;
    max-width: 600px;
    border-radius: 20px;
}

.hero-content-stack {
    padding: 0rem 2rem;
}

.hero-video-container {
    padding-top: 6px;
    padding-bottom: 4rem;
}

@media only screen and (max-width: 900px) {
    .hero-title {
        text-align: center;
        padding-top: 2rem;
    }
    .hero-subtitle {
        text-align: center;
    }
    .hero-button-container {
        justify-content: center;
    }
    .hero-content-stack {
        padding: 0rem 0.5rem 4rem 0.5rem;
    }
    .hero-video-container {
        padding-bottom: 0rem;
    }
}
