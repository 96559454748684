.faq-page {
    padding: 2rem 0rem;
}

.faq-section {
    padding: 2rem 0rem;
}

.faq-item {
    padding: 0.4rem 2rem;
}

.faq-question {
    cursor: pointer;
    padding: 0.6rem 0.6rem;
}

.faq-question:hover {
    border-radius: 4px;
    background-color: #ededed;
}

.faq-answer {
    padding: 0rem 2rem;
    max-height: 0;
    overflow: hidden;
    transition:
        max-height 0.4s ease-out,
        opacity 0.4s ease-out;
    opacity: 0;
}

.faq-answer.show {
    max-height: 1000px; /* Arbitrary large value to ensure the content fits */
    opacity: 1;
}

.faq-answer-text {
    padding: 1rem 0rem;
}

.faq-answer code {
    background-color: #ededed;
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
    color: black;
}

.faq-answer a {
    color: midnightblue;
}

.faq-image {
    width: 100%;
    margin: 1rem 0rem;
    border-radius: 0.4rem;
    box-shadow: 0px 4px 8px gainsboro;
}
