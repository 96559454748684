/* Colors */
.background-primary {
    background-color: #fafafc;
}

.background-secondary {
    background-color: #f5f5f7;
}

.foreground-highlight {
    color: #507cd1;
}

/* Typography */
.text-eyebrow {
    font-family: "Nunito sans", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    opacity: 0.9;
    padding: 0.8rem 0rem;
}

.text-title {
    font-family: "Poppins", sans-serif;
    font-size: clamp(2.2rem, 3.8vw, 4rem);
    font-weight: 700;
    opacity: 0.84;
}

.text-heading {
    font-family: "Poppins", sans-serif;
    font-size: clamp(1.8rem, 2.3vw, 2.4rem);
    font-weight: 600;
    opacity: 0.84;
}

.text-heading-small {
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    opacity: 0.84;
}

.text-description {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    opacity: 0.7;
}

.text-description-action {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem;
    font-weight: 800;
    opacity: 0.8;
}

/* Containers */
.section-title {
    margin: 0;
    text-align: center;
    padding: 3rem 0rem;
}

.card {
    border-radius: 2rem;
    box-shadow: 0rem 0.5rem 0.8rem gainsboro;
    padding: 2.4rem;
}

/* Interactable */
.button {
    font-family: "Nunito sans", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    flex-grow: 1;
    max-width: 160px;
    min-height: 50px;
    margin: 0 10px;
    border-radius: 1rem;
    border-color: rgb(20, 20, 20);
    border-width: 0px;
    border-style: solid;
    background-color: rgb(20, 20, 20);
    color: rgb(222, 222, 222);
    box-shadow: 0px 4px 8px gainsboro;
    cursor: pointer;
    transition:
        background-color 0.2s ease,
        box-shadow 0.2s ease,
        color 0.2s ease;
}

.button:hover {
    background-color: rgb(40, 40, 40);
    box-shadow: 0px 8px 8px gainsboro;
}

.button-secondary {
    background-color: rgb(230, 230, 230);
    box-shadow: 0px 4px 8px white;
    color: rgb(0, 0, 0);
}

.button-secondary:hover {
    background-color: rgb(240, 240, 240);
    box-shadow: 0px 4px 8px white;
}
