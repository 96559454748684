@media (min-width: 900px) {
    .mac-window {
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-width: 1200px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .mac-header {
        background-color: #f4f4f4;
        border-bottom: 1px solid #ccc;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .mac-content {
        flex: 1;
        overflow-y: auto;
    }

    .ui-menu-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .ui-menu-red {
        background-color: #ff5f56;
    }

    .ui-menu-yellow {
        background-color: #ffbd2e;
    }

    .ui-menu-green {
        background-color: #27c93f;
    }

    .product-listing-info {
        display: block !important;
    }
}

.mac-window {
    width: 80%;
    margin: 0 auto;
}

.product-listing-info {
    display: none;
}
