.testimonials-container {
    text-align: center;
    overflow: hidden;
}

.testimonials-title {
    padding-top: 2rem;
}

.rating {
    margin-top: auto;
    margin-bottom: 0;
}

.location {
    font-family: "Kanit", sans-serif;
    font-size: x-small;
    margin: 0;
}

.storeName {
    font-family: "Kanit", sans-serif;
    font-size: medium;
    margin: 0;
}

.cardd {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    height: auto;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* height: auto; */
}

.testimonials-button {
    padding: 48px;
}

.testimonials-button-learn {
    padding: 0;
}

.testimonials-content {
    padding: 24px;
}

.logo-image {
    height: 2.4rem;
    opacity: 0.6;
}
