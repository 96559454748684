.contact-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.contact-title {
    margin: 0;
}

.contact-title-title {
    font-family: "Nunito Sans", sans-serif;
    font-size: min(64px, calc(24px + 2vw));
    font-weight: 500;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-title-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: x-large;
    font-weight: 500;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    text-decoration: none;
    color: rgb(35, 35, 35);
}

.contact-form {
    padding: 1em;
    margin: 0;
}

.contact-form-title {
    font-family: "Nunito Sans", sans-serif;
    font-size: x-large;
    font-weight: 500;
    padding-bottom: 1rem;
}

.contact-form-emailJS {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    /* text-align: left; */
}

.contact-button {
    max-width: 15ch;
    width: 100%;
}

.name-fields {
    display: flex;
    /* gap: 1em; */
    /* width: 100%; */
    flex-wrap: wrap;
    align-items: center;
}

.contact-textfield {
    flex: 1 1 calc(50% - 0.5rem); /* Set flexible width for each field */
    max-width: calc(50% - 0.5rem); /* Limit maximum width for each field */
    margin-bottom: 1em; /* Add space between fields */
}

/* Style the dialog content */
.ContactViewDialog {
    padding: 0px;
    margin: 0;
}

/* Style the dialog title */
.ContactViewDialogTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Style the dialog message */
.ContactViewDialogMessage {
    font-size: 1.2rem;
    padding-top: 1rem;
}

/* Style the OK button */
.ContactViewDialogButton {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ContactViewDialogButton:hover {
    background-color: #0056b3;
}
