.emailus-container {
    min-height: 60vh;
    padding: 2rem;
}

.emailus-textblock {
    align-self: center;
    padding-right: 1rem;
}

.emailus-title {
    font-size: 1.5rem;
}

.emailus-title-blankwall {
    font-size: clamp(2rem, 7vw, 10rem);
    margin: 0;
    text-align: left;
}

.emailus-description {
    font-size: 1.8rem;
}

.emailus-imageblock {
    /* background-color: #222222; */
    margin: 0;
    align-self: center;
    text-align: center;
}

.emailus-email-link {
    text-decoration: none;
    font-size: clamp(1rem, 3vw, 3rem);
    font-weight: 700;
    font: "Nunito sans" sans-serif;
    color: rgba(15, 15, 15, 0.75);
}

@media (max-width: 900px) {
    .emailus-imageblock {
        text-align: left;
        align-self: auto;
    }

    .emailus-container {
        padding-left: 2rem;
    }
}
