.widget-container {
    display: block;
    margin: 0;
    padding: 4rem;
    min-height: 100vh;
}

.widget-main-title {
    font-family: "Kanit", sans-serif;
    font-size: min(58px, calc(28px + 2vw));
    font-weight: 700;
    /* text-align: center; */
}

.widget-step-list-container {
    margin: 2rem;
}

.widget-steps-container {
}

.widget-step-title {
    padding: 2rem;
}

.widget-step-subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: large;
    font-weight: 700;
    padding-left: 3rem;
}

.widget-step-content {
    font-family: "Nunito Sans", sans-serif;
    font-size: large;
    font-weight: 500;
    padding-left: 3rem;
}

.widget-step-list-content {
    font-family: "Nunito Sans", sans-serif;
    font-size: large;
    font-weight: 500;
    padding: 1rem;
}

pre {
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ccc;
    line-height: 1.4;
    overflow-x: auto;
    border-radius: 5px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.widget-code-container {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
}

.widget-code-content {
    padding-top: 10px;
    /* position: relative; */
}

code {
    font-family: "Courier New", Courier, monospace;
    font-size: medium;
    color: #333;
}

.widget-code-header {
    background-color: #cdc6c6;
    color: #fff;
    padding: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: right;
}

.widget-copy-button {
    /* background-color: #007acc;
    color: #fff; */
}

.widget-copy-button:hover {
    color: #fff;
}

.widget-help-title {
    font-family: "Kanit", sans-serif;
    font-size: xx-large;
    font-weight: 700;
    padding: 2rem;
}

.widget-gradient-text {
    /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: linear-gradient(45deg, #3fa9ff, #3a0069);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.widget-divider {
    width: 100%;
    text-align: center;
    margin-top: 20px; /* Adjust the spacing above the divider */
    margin-bottom: 20px; /* Adjust the spacing below the divider */
}

/* Style the line in the divider */
.widget-divider-line {
    width: 100%;
    height: 1px;
    background-color: #ddd; /* Divider line color */
    display: inline-block;
}
