.appbar-button {
    box-shadow: none;
    text-decoration: none;
    text-transform: none;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 6px 12px;
    border: 1px solid;
    border-radius: 8px;
    font-family: "Nunito sans", sans-serif;
    margin-left: 1em;
}

.appbar-button-primary {
    color: rgb(222, 222, 222);
    background-color: rgb(20, 20, 20);
    border-color: rgb(20, 20, 20);
}

.appbar-button-secondary {
    color: rgb(20, 20, 20);
    background-color: transparent;
    border-color: transparent;
}

.appbar-button:hover {
    background-color: transparent;
    border-color: #000;
    color: #000;
    cursor: pointer;
}

.appbar-ig {
    align-items: bottom; /* This centers content vertically */
}

.appbar-shopify {
    width: 18px;
    margin-right: 6px;
}

.appbar-logo {
    width: 48px;
    height: 48px;
    padding-right: 10px;
}

.Dreamscape-Labs {
    font-family: "Poppins", sans-serif;
    font-size: x-large;
    font-weight: 700;
    color: black;
}

.hamburger {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.5rem;
    padding-top: 1rem;
    cursor: pointer;
    appearance: none;
    background: none;
    outline: none;
    border: none;
    z-index: 99;
}

.hamburger .bar,
.hamburger:after,
.hamburger:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: black;
    margin: 5px 0px;
    transition: 0.3s;
}

.hamburger.is-active:before {
    transform: rotate(-45deg) translate(-10px, 0px);
}

.hamburger.is-active:after {
    transform: rotate(45deg) translate(-10px, 0px);
}

.hamburger.is-active .bar {
    opacity: 0;
}

.mobile-nav {
    position: fixed;
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    padding: min(30vh, 10rem) 2em;
    gap: 5rem;
    z-index: 98;
}

.mobile-nav a {
    text-align: end;
    margin-top: 0 auto 1rem;
    padding-right: 3rem;
    color: rgb(20, 20, 20);
    text-decoration: none;
    text-decoration-line: underline;
    text-underline-offset: 8px;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
}
