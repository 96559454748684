.feature-container {
    padding: 0;
    overflow: hidden;
    /* Texts are outside viewport, causing overflow */
}

.feature-section-normal {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem; /* 24px */
    padding-bottom: 1.5rem; /* 24px */
}

.feature-section-reverse {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1.5rem; /* 24px */
    padding-bottom: 1.5rem; /* 24px */
}

.feature-info-left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    padding-right: 0.5rem;
}

.feature-info-right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    padding-left: 0.5rem;
}

.feature-info-column-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
}

.feature-info-column-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
}

.feature-info-paragraph {
    text-align: center;
}

.feature-image-normal {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.feature-image-reverse {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
}

.feature-image {
    width: 100%;
}

/* slide in and out animations */
.slide-left {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(-50%);
}

.slide-right {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(50%);
}

.show-feature {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: all 0.5s;
}

@media (max-width: 900px) {
    .feature-info-column-left {
        text-align: center;
    }
    .feature-info-column-right {
        text-align: center;
    }
    .feature-info-left {
        text-align: center;
    }
    .feature-info-right {
        padding-top: 1rem;
        align-items: center;
        text-align: center;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 1s ease-out forwards;
}
