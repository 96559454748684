.integration-container {
    background-image: linear-gradient(
        to top,
        #d5d4d0 0%,
        #d5d4d0 1%,
        #eeeeec 31%,
        #efeeec 75%,
        #e9e9e7 100%
    );
}

.integration-title-container {
    padding: 2rem 0;
    padding-bottom: 0;
}

.integration-step-number {
    padding-left: 1rem;
}

.integration-step-title {
    font-size: 1.5rem;
    padding-left: 1rem;
}

@media (min-width: 900px) {
    .integration-title-container {
        padding: 3rem 1rem;
    }

    .integration-step-number {
        font-size: 3rem;
        margin: 0;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .integration-step-title {
        font-size: clamp(1.2rem, 1.7vw, 3rem);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        /*
        For some reason using clamp as default
        and setting a fixed font-size in @media
        doesn't work.
        But the other way around works.
        */
    }
}
